.profil-main .download-bloc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    height: 355px;
    border-radius: 70px;
    gap: 15px;
    background: rgba(255, 255, 255, 0.7);
    transition: 0.3s;
    @media (min-width: $phoneScreenSize) {
        width: 320px;
        height: 460px;
    }
    & h2 {
        font-family: $policeTitles;
        font-size: $phoneFontSizeTitle;
        @media (min-width: $phoneScreenSize) {
            font-size: $desktopFontSizeTitle;
        }
    }
    & .qrcode-download {
        opacity: 1;
        width: 180px;
        height: 180px;
        padding: 10px;
        border-radius: 20px;
        background-color: $colour-two;
        @media (min-width: $phoneScreenSize) {
            border-radius: 40px;
            width: 250px;
            height: 250px;
            padding: 15px;
        }
    }
    & a {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: $phoneFontSizeTxt;
        color: $colour-four;
        transition: .2s;
        @media (min-width: $phoneScreenSize) {
            font-size: $desktopFontSizeTxt;
        }
    }
    & a:hover {
        scale: 1.05;
        color: $colour-two;
        text-decoration: underline;
        transition: .2s;
    }
}

.profil-main .download-bloc:hover {
    scale: 1.03;
    background: rgba(255, 255, 255, 1);
    transition: 0.3s;
}