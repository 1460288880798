.backTop {
    display: flex;
    width: 70px;
    height: 60px;
    text-align: center;
    background-color: rgba($colour-four, .7);
    color: white;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    opacity: 0.7;
    position: fixed;
    bottom: 3%;
    right: 5%;
    transition: .5s;
    @media (min-width: 1240px) {
        display: none;
    }
    & i {
        font-size: $phoneFontSizeTitle;
        @media (min-width: $phoneScreenSize) {
            font-size: $desktopFontSizeTitle;
        }
    }
}

.backTop:hover {
    opacity: 1;
    transition: .5s;
}

#backTop-anchor {
    position:absolute;
    top: 0;
    left: 0;
}