.socialLink-bloc {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;    
    background-color: $colour-five;
    gap: 30px;
    @media (min-width: $phoneScreenSize) {
        gap: 100px;
    }
    & .social-link-card {
        height: 250px;
        width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 20px;
        background-color: $colour-two;
        padding: 20px 10px;
        border-radius: 70px;
        border: solid 3px $colour-four;
        // transition: transform 5.5s ease-out;
        @media (min-width: $phoneScreenSize) {
            gap: 30px;
            width: 200px;
            height: 315px;
            border-width: 5px;
        }
        & i {
            font-size: 40px;
            color: $colour-three;
            transition: .5s;
            @media (min-width: $phoneScreenSize) {
                font-size: 50px;
            }
        }
        & .icon-params {
            font-size: $phoneFontSizeTitle;
            margin-bottom: -20px;
            @media (min-width: $phoneScreenSize) {
                font-size: $desktopFontSizeTitle;
            }
        }
        & .qrcode-social-link {
            width: 100px;
            height: 100px;
            padding: 5px;
            background-color: $colour-three;
            border-radius: 10px;
            transition: .5s;
            @media (min-width: $phoneScreenSize) {
                width: 120px;
                height: 120px;
                border-radius: 15px;
            }
        }
        & p {
            color: $colour-three;
            text-align: center;
            @media (min-width: $phoneScreenSize) {
                font-size: $desktopFontSizeTxt;
            }
        }
    }
    & .social-link-card:hover {
        transform: rotate3d(0, 1, 0, 360deg);
        transition: transform 1.5s ease-out;
        & i {
            color: $colour-four;
            transition: .5s;
        }
        & .qrcode-social-link {
            background-color: $colour-four;
            transition: .5s;
        }
        & p {
            color: $colour-four;
            text-decoration: underline;
            scale: 1.1;
            transition: .5s;
        }
    }
}
