.recents-projects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    @media (min-width: $phoneScreenSize) {
        margin-top: 70px;
    }
    & h2 {
        font-family: $policeTitles;
        font-size: $phoneFontSizeTitle;
        letter-spacing: 3px;
        color: $colour-three;
        align-self: flex-start;
        margin-left: 30px;
        @media (min-width: $phoneScreenSize) {
            font-size: $desktopFontSizeTitle;
            letter-spacing: 4px;
            margin-left: 60px;
        }
    }
    & .current-project-bloc-style {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        & .project.active {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 30px;
            width: 100%;
            padding: 10px;
            background-color: $colour-two;
            border-radius: 15px;
            & .currentProject-box {
                display: flex;
                align-items: flex-end;
                height: 355px;
                background-position: center;
                object-fit: cover;  
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 15px;
                @media (min-width: $phoneScreenSize) {
                    height: 430px;
                }
                & .currentProject-box-quote {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: rgba($colour-two, 0.75);
                    @media (min-width: $phoneScreenSize) {
                        transform: translateY(150px);
                        transition: transform 0.3s ease-out;
                    }
                    & i {
                        font-size: 50px;
                        margin: 0 10px;
                        opacity: 0.7;
                        color: $colour-four;
                        cursor: pointer;
                        transition: .2s;
                        @media (min-width: $phoneScreenSize) {
                            font-size: 90px;
                            &:hover {
                                opacity: 1;
                                scale: 1.03;
                                transition: .2s;
                            }
                        }
                    }
                    & .recent-project-info {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 5px;                        
                        padding: 10px;
                        border-radius: 15px 15px 0 0;
                        & h3 {
                            font-family: $policeTitles;
                            font-size: $phoneFontSizeTitle;
                            color: $colour-four;
                            font-weight: 600;
                            letter-spacing: 2px;
                            @media (min-width: $phoneScreenSize) {
                                font-size: $desktopFontSizeTitle;
                                letter-spacing: 3px;
                            }
                        }
                        & p {
                            font-size: $phoneFontSizeTxt;
                            @media (min-width: $phoneScreenSize) {
                                font-size: $desktopFontSizeTxt;
                            }
                        }
                        & a {
                            font-size: $phoneFontSizeTxt;
                            color: $colour-three;
                            background-color: $colour-four;
                            padding: .5em 1em;
                            border-radius: 30px;
                            @media (min-width: $phoneScreenSize) {
                                font-size: $desktopFontSizeTxt;
                                transition: .2s;
                                &:hover {
                                    scale: 1.05;
                                    transition: .2s;
                                }
                            }                            
                        }
                    }
                }                
            }
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .project.active:hover {
        & .currentProject-box:hover{
            & .currentProject-box-quote {
                transform: translateY(0);
                transition: transform 0.3s ease-out;                
            }
        }        
    }
}