html {
    scroll-behavior: smooth;
}

body {
    font-family: $policeTxt, $policeDefault;    
    background-color: $colour-five;
}

main {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 125px; 
    @media (min-width: $phoneScreenSize) {
        margin-left: 50px;
        margin-right: 50px;   
        margin-top: 155px; 
    }
    @media (min-width: 1240px) {
        max-width: 1240px;
        margin: auto;
        margin-top: 155px;
    }
}

a {
    color: black;
    text-decoration: none;
    // font-family: $policeLink;
}

h1, h2, h3, h4{
    margin: 0;
    font-weight: 500;
}

p{
    margin: 0;
}

figure {
    margin: 0;
}

ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
