.home-content {
    background-color: $colour-three;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    & article {
        width: 70%;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-family: $policeTxt;
        font-size: $phoneFontSizeTxt;
        @media (min-width: $phoneScreenSize) {
            font-size: $desktopFontSizeTxt;  
            gap: 20px;
        }
        & h2 {
            font-size: $phoneFontSizeTitle;
            font-family: $policeTitles;
            letter-spacing: 2px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (min-width: $phoneScreenSize) {
                font-size: $desktopFontSizeTitle;   
            }
            & img {
                width: 90px;
                height: 90px;
                border-radius: 50%;
                border: double 5px $colour-four;
                @media (min-width: $phoneScreenSize) {
                    width: 120px;
                    height: 120px 
                }
            }
        }
        & p {
            line-height: 23px;           
            @media (min-width: $phoneScreenSize) {
                line-height: 27px;  
            } 
        }
        & .link-home-content{
            align-self: flex-end;         
            padding: 10px;
            background-color: $colour-two;   
            border-radius: 10px;
            color: $colour-four;
            transition: .2s;
        }
        & .link-home-content:hover{
            color: $colour-three;
            scale: 1.05;
            transition: .2s;
        }
    }
}