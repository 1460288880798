header {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $colour-four;
    padding: 10px 0;
    box-shadow: 5px 5px 20px $colour-one;
    @media (min-width: $phoneScreenSize) {               
        padding: 10px 0;
        justify-content: space-around;
        flex-direction: row;       
    }
    & .logo img {
        width: 120px;
        @media (min-width: $phoneScreenSize) {
            width: 190px;
        }
    }
    & nav {
        display: flex;
        gap : 20px;
        font-size: $phoneFontSizeTitle;
        align-items: center;
        flex-wrap: wrap;
        @media (min-width: $phoneScreenSize) {
            font-size: $desktopFontSizeTitle;
            & a:hover {
                color: $colour-three;
                text-decoration: underline;
                scale: 1.2;
                transition: 0.3s;
            }
        }
        & a {
            letter-spacing: 2px;
            color: $colour-two;
            transition: 0.3s;
            @media (min-width: $phoneScreenSize) {
                letter-spacing: 3px;
            }
        }        
    }
}

.nav-point {
    width: 7px;
    height: 7px;
    background-color: $colour-two;
    border-radius: 50%;
    @media (min-width: $phoneScreenSize) {
        width: 8px;
        height: 8px;
    }
}