.details-goal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
    @media (min-width: $phoneScreenSize) {
        margin-top: 70px;
        gap: 35px;
    }
    & h2 {
        font-family: $policeTitles;
        font-size: $phoneFontSizeTitle;
        letter-spacing: 3px;
        color: $colour-three;
        align-self: flex-start;
        margin-left: 30px;
        @media (min-width: $phoneScreenSize) {
            font-size: $desktopFontSizeTitle;
            letter-spacing: 4px;
            margin-left: 60px;
        }
    }
    & div {
        overflow: hidden;
        width: 100%;
        height: 400px;
        border-radius: 10px;
        & iframe {
            width: 100%;
            height: 400px;
        }
    }
}