.profil-main {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    gap: 30px;
    @media (min-width: $phoneScreenSize) {
        width: 80%;
        margin-bottom: 50px;
        gap: 50px;
    }
    & section {
        background-color: $colour-three;
        padding: 30px 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        @media (min-width: $phoneScreenSize) {
            padding: 30px 50px;
            gap: 35px;
        }
        & h1 {
            font-size: $phoneFontSizeTitle;
            font-family: $policeTitles;
            letter-spacing: 2px;
            align-self: flex-start;
            margin-left: 40px;
            @media (min-width: $phoneScreenSize) {
                font-size: $desktopFontSizeTitle;
                letter-spacing: 3px;
                margin-left: 60px;
            }
        }
        & p {
            font-size: $phoneFontSizeTxt;
            font-family: $policeTxt;
            @media (min-width: $phoneScreenSize) {
                font-size: $desktopFontSizeTxt;
            }
        }
    }
    & .sectionAndPicture {
        @media (min-width: $phoneScreenSize) {
            display: flex;    
            gap: 15px;         
        }
        & aside img {
            display: none;
            height: 550px;  
            overflow: hidden;
            padding: 3px;
            background-color: $colour-two;
            border-radius: 10px;
            @media (min-width: $phoneScreenSize) {
                display: block;
                border-radius: 10px;
            }
        }        
    }
    & .download-style {
        width: 100%;
        padding: 30px 0;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        background-image: url('../../assets/pictures/img-annecy-jour.jpg');
        background-position: center;
        background-size: cover;
        background-color: #f5f5f565;
        background-blend-mode: lighten;
        @media (min-width: $phoneScreenSize) {
            padding: 50px 0;
        }
    }
}

