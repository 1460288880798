.collapse {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .collapse-title {
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $colour-five;
        font-size: $phoneFontSizeTitle;
        font-family: $policeTitles;
        letter-spacing: 1px;
        color: $colour-three;
        padding: 10px 15px;
        border-radius: 30px;
        cursor: pointer;
        transition: .2s;
        & h2 {
            display: flex;
            align-items: center;
            gap: 5px;
            @media (min-width: $phoneScreenSize) {
                gap: 10px;
            }
        }
    }
    & .collapse-title:hover {
        color: $colour-two;
        transition: .2s;
    }
    & .collapse-content {
        position: relative;
        top: -15px;
        background-color: $colour-two;
        font-size: $phoneFontSizeTxt;
        width: 90%;
        padding: 50px 40px;
        border-radius: 10px;
        color: $colour-one;
        & ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 15px;
            & li {
                display: flex;
                align-items: baseline;
                gap: 15px;
                @media (min-width: $phoneScreenSize) {
                    font-size: $desktopFontSizeTxt;
                    gap: 20px;
                }
                & .icon-illustration-list {
                    display: flex;
                    margin-left: -15px;
                    @media (min-width: $phoneScreenSize) {
                        margin-left: -20px;
                    }
                }
                & i {
                    width: 15px;
                }
            }
        }
        & p a {
            color: $colour-four;
            transition: .2s;
        }
        & p a:hover {
            text-decoration: underline;
            color: $colour-three;
            transition: .2s;
        }
    }
}

.false {
    height: 45px;
    & .collapse-title {
        & .chevron-up {
            rotate: (-180deg);
        }
    }
    & .collapse-content {
        transform-origin: top;
        transform: scaleY(0);
        transition: transform .7s cubic-bezier(.32,0,.07,1);
    }
}

.active {
    & .collapse-title {
        color: $colour-two;
    }
    & .collapse-content {
        transform-origin: top;
        transform: scaleY(1);
        transition: transform 1s cubic-bezier(.32,0,.07,1);
    }
}