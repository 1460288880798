footer {
    padding-top: 15px;
    padding-bottom: 5px;
    margin-top: 40px;
    background-color: $colour-four;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: $phoneFontSizeTxt;
    @media (min-width: $phoneScreenSize) {
        padding-top: 20px;
        padding-bottom: 10px;
        margin-top: 70px;
        font-size: $desktopFontSizeTxt;
        gap: 15px;
    }
    & img {
        display: none;
        @media (min-width: $phoneScreenSize) {
            display: block;
            width: 170px;
        }
    }
    & ul {
        display: flex;
        align-items: center;
        gap: 20px;
        @media (min-width: $phoneScreenSize) {
            gap: 30px;
        }
        & li a {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $colour-two;
            transition: .2s;
            & i {
                font-size: $phoneFontSizeTitle;
                @media (min-width: $phoneScreenSize) {
                    font-size: $desktopFontSizeTitle;
                }
            }
            & .dl-cv-footer {
                font-size: $phoneFontSizeTxt;
                @media (min-width: $phoneScreenSize) {
                    font-size: $desktopFontSizeTxt;
                }
            }
            & p {
                color: $colour-two;
                transition: .2s;
                @media (min-width: $phoneScreenSize) {
                    font-size: $desktopFontSizeTxt;
                }
            }
        }
    }
    & ul li a:hover {
        transition: .2s;
        color: $colour-three;
        
        & p {
            color: $colour-three;
            transition: .2s;
            text-decoration: underline;
        }
    }
    & p {
        color: $colour-three;
        text-align: center;
        @media (min-width: $phoneScreenSize) {
            font-size: $phoneFontSizeTxt;
        }
    }
}