.error-main {
    display: flex;
    justify-content: center;
    & section{
        width: 70%;
        margin: 70px 0;
        padding: 50px;
        border-radius: 20px;
        background-color: $colour-three;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 80px;
        & img {
            width: 350px;
            border-radius: 20px;
            @media (min-width: $phoneScreenSize) {
                width: 400px;
            }
        }
        & a:hover {
            text-decoration: underline;
            color: $colour-two;
            scale: 1.2;
            transition: .2s;
        }
        & a {
            transition: .2s;
            font-size: $phoneFontSizeTxt;
            @media (min-width: $phoneScreenSize) {
                font-size: $desktopFontSizeTxt;  
            }
        }
        & h2 {
            font-family: $policeTitles;
            font-size: $phoneFontSizeTitle;
            font-weight: 600;
            @media (min-width: $phoneScreenSize) {
                font-size: $desktopFontSizeTitle;  
            }
        }
        & p {
            font-size: $phoneFontSizeTitle;
            font-weight: 600;
            font-family: $policeTxt;
            @media (min-width: $phoneScreenSize) {
                font-size: $desktopFontSizeTitle;  
            }
        }
    }
}
    